<template>
  <div class="data-box">
    <van-nav-bar
        title="物资回收"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">交回人</div>
          <div class="span-input" @click="editPerson">
            <span>{{materialInfo.designation ? materialInfo.designation : "请选择人员"}}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
      </div>
      <div class="body-div" v-show="materialList.length > 0">
        <div class="body-div-note">
          请注意需回收物资完好齐全
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt style="width: 33.3%;">物品名称</dt>
              <dt style="width: 33.3%;">已领数量</dt>
              <dt style="width: 33.3%;" @click="changeAllItem">
                <i :class="[isCheckedAll ? 'icon-border-checked-yes item-check' : 'icon-border-checked-no', 'iconfont']"></i>
              </dt>
            </tr>
            <tr v-for="(item,index) in materialList" :key="index" v-show="item.num3">
              <dd style="width: 33.3%;color: #3476FF;" @click="showDetails(item)"><span style="color: #FA5555;">*</span>{{ item.str }}</dd>
              <dd style="width: 33.3%;">{{ item.num4 }}</dd>
              <dd style="width: 33.3%;" @click="changeItem(index)">
                <i :class="[item.isChecked ? 'icon-border-checked-yes item-check' : 'icon-border-checked-no', 'iconfont']"></i>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div" v-show="materialList.length > 0">
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">物资图片</div>
          <div class="span-value">
            <van-uploader name="material" v-model="imageList" :after-read="afterRead" max-count="9" accept="image/*" multiple>
              <template>
                <div class="upload-div">
                  <i class="iconfont icon-plus-round"></i>
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
        <div class="body-div-prompt">
          温馨提示：请上传领取人与所领取物资合影照片
        </div>
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">订单要求</div>
          <div class="span-input">
            <van-field
                v-model="materialInfo.note"
                rows="3"
                type="textarea"
                maxlength="200"
                placeholder="请输入回收备注"
                show-word-limit
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot" v-if="materialList.length > 0">
      <van-button class="btn" round block type="default" @click="onCancel">取消</van-button>
      <van-button class="btn" round block type="info" @click="onSure">保存</van-button>
    </div>
    <van-action-sheet v-model="showMaterialPerson" :actions="materialPersonActions" @select="selectPerson"/>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import upload from "@/assets/common/upload";
import {
  canSelectStayFoodGrantPersons,
  showActivityMaterial,
  grantMaterial
} from "@/api/index";

export default {
  name: "orgMaterial_recycling",
  data() {
    return {
      showMaterialPerson: false,
      materialPersonActions: [],
      materialList: [],
      materialInfo: {
        designation: "",
        note: ""
      },
      isCheckedAll: false,
      imageList: []
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getActiveObject();
      if(that.$store.getters.getSelectRecyclingPersonId){
        that.getMaterialInfo(that.$store.getters.getSelectRecyclingPersonId);
      }
    },
    //获取活动对象
    getActiveObject(){
      let that = this;
      let params = {
        actId: that.$store.getters.getActivityId,
        activityIds: [0,1],
        pageNum: 1,
        pageSize: 9999,
        type: 1
      };
      canSelectStayFoodGrantPersons(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialPersonActions = res.data.records;
          that.materialPersonActions.forEach(item => {
            item.name = item.designation;
          })
        })
      })
    },
    getMaterialInfo(id){
      let that = this;
      let params = {
        ayId : id
      };
      showActivityMaterial(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialInfo = res.data;
          let array = [];
          that.materialInfo.materialList.forEach(item => {
            if(item.num3){
              array.push(item);
            }
          })
          that.materialList = array;
          that.materialList.forEach(item => {
            item.num4 = Math.abs(item.num4);
            item.isChecked = false;
          })
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    editPerson(){
      let that = this;
      that.showMaterialPerson = true;
    },
    selectPerson(item) {
      let that = this;
      that.getMaterialInfo(item.id);
      that.$store.commit('setSelectRecyclingPersonId', item.id);
      that.showMaterialPerson = false;
    },
    showDetails(item){
      let that = this;
      that.$router.push({
        name: 'orgCheck_material_details',
        query: {
          id: item.id
        }
      });
    },
    changeAllItem(){
      let that = this;
      that.isCheckedAll = !that.isCheckedAll;
      that.materialList.forEach(item => {
        item.isChecked = that.isCheckedAll;
      })
    },
    changeItem(index){
      let that = this;
      that.materialList[index].isChecked = !that.materialList[index].isChecked;
      this.$set(that.materialList, index, that.materialList[index]);
    },
    afterRead(file,detail){
      let that = this;
      upload.uploadFile(file,res => {
        if(detail.name === "material"){
          that.imageList[res.index].url = res.url;
        }
      },err => {
        console.log(err);
      });
    },
    onCancel(){
      this.$router.go(-1);
    },
    onSure(){
      let that = this;
      let isCheck = false;
      that.materialList.forEach(item => {
        if(item.isChecked){
          isCheck = true;
        }
      })
      if(!isCheck){
        that.$toast('请勾选已回收的物资！');
        return;
      }
      if(that.imageList.length === 0){
        that.$toast('请上传物资图片！');
        return;
      }
      let array = [];
      let images = [];
      that.imageList.forEach(item => {
        images.push(item.url);
      })
      that.materialList.forEach(item => {
        let obj = {
          "activityId": that.$store.getters.getSelectMaterialPersonId,
          "materialType": item.id,
          "num": item.num4,
          "note": that.materialInfo.note,
          "picture": images.join(","),
          "type": 1
        };
        array.push(obj);
      })
      grantMaterial(array).then(res => {
        that.$tool.resValidation(res, () => {
          this.$router.go(-1);
          that.$toast.success('物资回收成功！');
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.item-check{
  color: #3476FF;
}
.upload-div{
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  background-color: #F6F9FF;
  border-radius: 10px;
  text-align: center;
  i{
    color: #D6E3FE;
    opacity: 0.5;
  }
}
::v-deep .van-uploader__preview {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  .van-uploader__preview-image {
    display: block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    img{
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }
}
</style>